<template>
  <vca-card>
    <vca-card v-if="!needConsent" class="text-center shadowed">
      <h1>{{ $t("consent.redirect") }}</h1>
    </vca-card>
    <vca-card
      style="margin: auto; width: 50%"
      v-else
      class="text-center shadowed"
    >
      <p
        v-if="$i18n.locale == 'de' || $i18n.locale == 'ch'"
        style="white-space: pre-wrap; padding: 3em"
        v-html="consent.consent.text"
      ></p>
      <p
        v-else
        style="white-space: pre-wrap; padding: 3em"
        v-html="consent.consent.text_en"
      ></p>
      <vca-field-row>
        <button class="vca-button button" @click.prevent="confirm">
          {{ $t("consent.confirm") }}
        </button>
      </vca-field-row>
    </vca-card>
  </vca-card>
</template>
<script>
export default {
  name: "ConsentPage",
  props: {
    consent_challenge: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      consent: {},
      needConsent: false,
    };
  },
  methods: {
    confirm() {
      this.consent.check = true;
      this.$store.commit("login/consent", this.consent);
      this.$store.dispatch("login/consent").then((response) => {
        console.log(response);
        this.$store.dispatch("login/consent_challenge").then((response) => {
          window.location = response.redirect_to;
        });
      });
    },
  },
  created() {
    this.$store.commit("login/consent_challenge", this.consent_challenge);
    this.$store
      .dispatch("login/consent_challenge")
      .then((response) => {
        window.location = response.redirect_to;
      })
      .catch((error) => {
        if (error.response.data.message === "no_consent") {
          (this.consent = error.response.data.payload[0]),
            (this.needConsent = true);
        }
        console.log(error.response);
      });
  },
};
</script>
